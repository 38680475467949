import axiosClient from "@/axios";
import { AxiosPromise } from "axios";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";

const accountService = {
  getAccountDetails(): AxiosPromise<AccountDetailsResponse> {
    return axiosClient.get("/users/me/account_details");
  },
  changePassword(newPassword: string): AxiosPromise {
    return axiosClient.put("/users/password", {
      new_password: newPassword,
    });
  },
};

export default accountService;
