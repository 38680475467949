
import { defineComponent, PropType, ref } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import ExpandButton from "@/components/ui/ExpandButton.vue";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import { EventBus } from "@/common/Tools";
import router from "@/router";

export default defineComponent({
  name: "SubcustomerListItem",
  components: { ExpandButton, DefaultButton },
  props: {
    subcustomer: {
      required: true,
      type: Object as PropType<AccountDetailsResponse>,
    },
  },
  setup() {
    const isExpanded = ref<boolean>(false);
    const showSubcustomerParcels = (customerId: number) => {
      if (customerId) {
        EventBus.emit("changeCustomer", customerId);
        router.push({ name: "parcels.list" });
      }
    };
    return {
      isExpanded,
      showSubcustomerParcels,
    };
  },
});
