
import { defineComponent } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import DefaultInput from "@/components/form/DefaultInput.vue";
import accountService from "@/services/account.service.ts";

export default defineComponent({
  name: "ChangePasswordModal",
  components: { DefaultInput, DefaultButton },
  data(): {
    isLoading: boolean;
    newPassword: string;
    newPasswordRepeat: string;
    finished: boolean | null;
  } {
    return {
      isLoading: false,
      newPassword: "",
      newPasswordRepeat: "",
      finished: null,
    };
  },
  methods: {
    async changePassword() {
      this.isLoading = true;
      accountService
        .changePassword(this.newPassword)
        .then(() => (this.finished = true))
        .catch(() => (this.finished = false))
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    correctLength(): boolean {
      return (
        !this.newPassword ||
        (this.newPassword.length >= 4 && this.newPassword.length <= 70)
      );
    },
    passwordsRepeated(): boolean {
      return (
        !this.newPassword ||
        !this.newPasswordRepeat ||
        this.newPassword === this.newPasswordRepeat
      );
    },
    validPassword(): boolean {
      return this.correctLength && this.passwordsRepeated;
    },
  },
});
