
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    appendix: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    hasError(): boolean {
      return this.isError || !!this.error;
    },
    placeholderTranslation(): string {
      if (!this.placeholder) {
        return "";
      }

      return this.$t(this.placeholder);
    },
    appendixTranslation(): string {
      if (!this.appendix) {
        return "";
      }

      return this.$t(this.appendix);
    },
    localValue: {
      get(): string {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
