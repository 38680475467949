import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col relative h-12" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "id", "placeholder", "step"]
const _hoisted_4 = {
  key: 1,
  class: "text-red-500 md:text-right text-light py-1"
}
const _hoisted_5 = {
  key: 2,
  class: "text-black absolute right-4 top-1.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass(["text-regular mb-2", { 'text-red-500': _ctx.hasError }])
        }, _toDisplayString(_ctx.$t(_ctx.label)), 11, _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type,
      id: _ctx.id,
      class: _normalizeClass(["border rounded bg-white px-4 py-1.5 text-black", { 'border-red-500': _ctx.hasError, 'pr-12': _ctx.appendix }]),
      placeholder: _ctx.placeholderTranslation,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      step: _ctx.step
    }, null, 10, _hoisted_3), [
      [_vModelDynamic, _ctx.localValue]
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.error)), 1))
      : _createCommentVNode("", true),
    (_ctx.appendix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.appendixTranslation), 1))
      : _createCommentVNode("", true)
  ]))
}