
import { defineComponent } from "vue";
import SectionWrapper from "@/components/layout/SectionWrapper.vue";
import InfoField from "@/components/ui/InfoField.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import ChangePasswordModal from "@/components/my-account/ChangePasswordModal.vue";
import Spinner from "@/components/ui/Spinner.vue";
import accountService from "@/services/account.service";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import SubcustomerListItem from "@/components/my-account/SubcustomerListItem.vue";

export default defineComponent({
  name: "Account",
  components: {
    SubcustomerListItem,
    ChangePasswordModal,
    Modal,
    DefaultButton,
    InfoField,
    SectionWrapper,
    Spinner,
  },
  data(): {
    showSecretsModal: boolean;
    isLoading: boolean;
    showChangePasswordModal: boolean;
    accountDetails: AccountDetailsResponse;
  } {
    return {
      showSecretsModal: false,
      isLoading: true,
      showChangePasswordModal: false,
      accountDetails: {},
    };
  },
  methods: {
    copySecret: (secret: string) => {
      navigator.clipboard.writeText(secret);
    },
  },
  mounted() {
    accountService
      .getAccountDetails()
      .then((response) => {
        this.accountDetails = response.data;
      })
      .catch(() => {
        this.accountDetails = {};
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
});
